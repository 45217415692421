.m-header-bg {
  background-color: $m-header-bg;
  color: $m-header-color !important;
}

body {
  //font-family: 'Open Sans',sans-serif;
}

.table thead tr th {
  border-top: none;
}

[v-cloak] {
  display: none !important;
}

.one-line {
    white-space: nowrap;
}

.btn:not(:disabled):not(.disabled) {

}

.btn.btn-round {
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    border-radius: 50%;
    padding: 0;
    background: #E9EEF2;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: #d9e2e9;
    }
}

.card-table {
    .card-body {
        padding: 0;
    }
}

.invalid-feedback {
  display: block !important;
}

.vue-loading {
  display: none;
}

[v-cloak].vue-loading {
  display: block;
}

.card {
  margin-bottom: 1rem;
}

.accordion .card {
  margin-bottom: 0;
}

.card-header {
  margin-bottom: 2rem;
  h1 {
    font-size: 2rem;
    small {
      font-size: 0.8rem;
    }
  }
}

.has-danger {
  .form-control {
    border-color: theme-color("danger");
  }
}

.form-control::-webkit-input-placeholder {
  color: #ccc;
}
.form-control::-moz-placeholder {
  color: #ccc;
}
.form-control::-ms-input-placeholder { /* edge prend l’élement avec 2 « : » */
  color: #ccc;
}
.form-control:-ms-input-placeholder { /* cet imbébile d’IE11 le prend avec un seul « : » */
  color: #ccc;
}
.list-group-item, .list-group-item:hover{ z-index: auto; }

.stats-chart-infos {
  margin-top: -2rem;
}
div.datePicker.is-invalid input{
  border-color: #dc3545;
}