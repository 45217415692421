.layout-min {

  .main-content {
    background-color: transparent;
  }

  .single-action-page {

    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: -1px -1px 10px #ccc;
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 30rem;

    img.logo {
      margin-bottom: 0rem;
      margin-top: 1rem;
      height: 100px;
    }

    form {
      padding: 15px;
      text-align: center;

      .input-group {
        margin: auto;
      }
    }
  }
}