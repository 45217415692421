.btn.toggle-feed {
    display: inline-block;
}

.badge.badge-feed {
    color: #ffc107;
    background-color: #E9EEF2;
    border: 1px solid white;
}

.col.col-feed {
    max-width: 30%;
    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }
    .list-group {
        max-height: 800px;
        @include media-breakpoint-down(lg) {
            max-height: 300px;
        }
        overflow-y: auto;
        .list-group-item {
            h5 {
                font-size: 1rem;
            }
        }
    }
}