.client-accounts {
    td.actions {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 1.5rem;

        a {
            margin-left: 10px;
        }
        a:hover {
            text-decoration: none;
        }
    }
}