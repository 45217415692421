body {
  background-color: $body-bg;

  .header {
    background-color: $m-header-bg;
  }

  .main-content {
    background-color: $m-content-bg;
    padding-bottom: 3rem;
  }

  .footer-container {

    background-color: $m-footer-bg;

    .footer {
      padding-top: 40px;
      padding-bottom: 40px;
      font-size: 12px;
      line-height: 1.5;
      color: $m-footer-color;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .footer-links {
        flex: 1;
        display: flex;
        flex-direction: column;

        a {
          padding: 0 1rem;
          color: $m-footer-link-color;
        }

        a:hover {
          color: $m-footer-link-hover-color;
        }
      }

      .footer-links.center {
        flex-direction: column;
        align-items: center;
      }

    }
  }

  .pointer {
    cursor: pointer;
  }

  .infobulle {
    position: relative;
    cursor: help;

    &:hover,
    &:focus {
      span {
        transform:scale(1) translateX(-50%);  
        opacity:1;
      }
    }

    span {
      position: absolute;
      top: -4.2em;
      left: 50%;
      z-index: 1; 
      white-space: nowrap;
      color: $gray-100;
      background: $gray-800;
      border-radius: .25rem;
      padding: .2rem .5rem;
      transform:scale(0) translateX(-50%);
      transition:all .25s;
      opacity:0;

      &:before {
        content: "▼";
        position: absolute;
        bottom: -1em;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: $gray-800;
      }
    }
  }

  .progress {
    position: relative;

    .progress-label {
      position: absolute;
      display: inline-block;
      width: 100%;
      text-align:center;
      font-weight: 700;
    }

    .progress-bar {
      background-color: #ff6969;
    }
  }
}