$public-header-height: 115px;

.layout-public {

  margin-right: 15px;
  margin-left: 15px;

  .header {
    min-height: $public-header-height;

    nav {
      position: absolute;
      color: #fff;
      z-index: $zindex-fixed;
      width: 100%;
      height: $public-header-height;
      display: flex;
      flex-direction: row;
      align-items: center;

      a.navbar-brand {
        padding-bottom: 0;
        padding-top: 0;
        img {
          height: 40px;
        }
      }

      .navbar-items {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex: 2;
        justify-content: flex-end;
        margin: 0;
        align-items: center;

        .nav-item {

          .nav-link {
            text-transform: uppercase;
            color: #fff;
          }
        }
      }

      .menu-toggler {
        color: #fff;
        background: none;
        font-size: 40px;
        cursor: pointer;
      }
    }

    .jumbotron {

      .header-title {
        position: relative;
        top: 250px;

        h1 {
          font-size: 2.5rem;
          text-align: center;
          color: #fff;
          text-transform: uppercase;
        }

        h3 {
          font-size: 1.10rem;
          text-align: center;
          color: #fff;
          margin-bottom: 0;
        }
      }

    }
  }

  .main-content {
    min-height: 300px;
  }
}

.layout-public.layout-public-min {
  nav {
    color: #29a7d6;

    .navbar-items {

      .nav-item {

        .nav-link {
          color: #29a7d6;
        }
      }
    }

    .menu-toggler {
      color: #29a7d6;
    }
  }
}