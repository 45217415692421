/*
 * Modifier ici les variables bootstrap
 * https://github.com/twbs/bootstrap/blob/v4.0.0-beta/scss/_variables.scss
 */

/*
 * Gamme de couleur des boutons de formulaire
 */
$m-primary-color: #29a7d6;
$m-success-color: #1cb9a4;
$m-secondary-color: #868e96;

/*
 * Couleur de fond et couleur du texte
 */
$m-body-bg: #e6e6e6;
$m-body-color: #292b2c;

$body-bg:       $m-body-bg;
$body-color:    $m-body-color;
$primary:       $m-primary-color;

/*
 * Style du header
 */
$m-header-bg: #1f4b73;
$m-header-color: #fff;
$m-header-link-color: #fff;
$m-header-link-hover-color: #d9e2e9;
$m-header-logo-height: 30px;
$m-header-dark: true;

/*
 * Style du contenu principal
 */
$m-content-width: 992px;
$m-content-bg: #f5f6f7;

/*
 * Style du footer
 */
$m-footer-bg: #1f4b73;
$m-footer-color: #fff;
$m-footer-link-color: #fff;
$m-footer-link-hover-color: #29a7d6;

$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$theme-colors: (
    primary: $m-primary-color,
    secondary: $m-secondary-color,
    success: $m-success-color,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800
);

/* breadcrumb */
$breadcrumb-bg:                     $gray-200;
$breadcrumb-padding-y:              0.75rem;
$breadcrumb-padding-x:              1rem;

/* ### Form ### */
$input-bg: $white;

/* border */
$border-radius: 0.25rem;

/* Card */
$card-bg: $white;

/* Table */
$table-bg: $white;
