.attestation {
  &.print {
    background-color: #fff;
    print-color-adjust:exact;
    -webkit-print-color-adjust:exact;

    .attestation-content {
      .page {
        margin: 0;
        padding-right: 0;
        padding-left: 0;
        box-shadow: none;
      }
    }
  }

  font-family: 'Crimson Text', serif;

  .attestation-submit {
    width: 210mm;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    padding-top: 0;
  }

  .attestation-header {
    text-align: center;

    .table {
      td {
        padding: 0;
        border: 1px solid #000;
      }
    }
  }

  .attestation-content {
    .page-break {
      break-before: always;
    }

    .page {
      break-inside: avoid;
      break-before: always;

      background-color: #fff;
      box-shadow: 1px 0px 5px grey;
      width: 210mm;
      margin: auto;
      //min-height: 297mm;
      padding: 10mm;
      margin-bottom: 2rem;//2
      font-size: 8pt;

      h1 {
        text-align: center;
        text-transform: uppercase;
        font-size: 20pt;
        margin: 0;
      }

      h2 {
        text-align: center;
        font-size: 16pt;
        margin-bottom: 20px;
        padding-bottom: 1pt;
      }

      h3 {
        font-size: 8pt;
        font-weight: bold;
        margin-bottom: 16px;
      }

      p {
        margin-bottom: .4rem;
      }

      .form {
        margin-bottom: 0; //16
        //line-height: 14px;

        p {
          display: -webkit-flex;
          display: flex;
          margin-bottom: 0;//2
        }

        label {
          display: inline-block;
          margin: 0;
          margin-right: 1rem;
        }

        .editable {
          font-family: 'Patrick Hand', cursive;
          display: inline-block;
        }

        span.editable, input[type='text'].editable {
          border: 0;
          background: none;
          border-bottom: 1px;
          border-bottom-style: dotted;
          padding-left: 1rem;
          padding-right: 1rem;
          min-width: 3rem;

          -webkit-flex-grow: 1;
          flex-grow: 1;
        }

        .digital {
          display: block;
          min-height: 2rem;
        }

        /* custom details css */

        p.form-type-radio, p.form-type-select {
          flex-direction: column;
          -webkit-flex-direction: column;

          label:first-of-type {
            margin-bottom: 0px;
          }
        }

        p.form-type-radio.detail-E,
        p.form-type-radio.detail-age,
        p.form-type-radio.detail-age_fenetre,
        p.detail-yes-no {
          flex-direction: row;
          -webkit-flex-direction: row;

          label:first-of-type {
            margin-bottom: 0px;
          }
        }
      }
      
      .signature-container {

        //border: 1px solid #000;
        padding-top: 20px;

        .signature-date {
          .editable:empty:before {
            content: '_ _ / _ _ / _ _ _ _ ';
            color: #ccc;
          }
        }
        
        .signature {
          label:nth-of-type(2) {
            -webkit-flex-grow: 1;
            flex-grow: 1;
            text-align: right;
          }
        }        
      }

      .form-name {
        display: -webkit-flex;
        display: flex;

        .form-lastname {
          display: inline-block;
          margin-right: 1rem;
        }

        .form-firstname {
          -webkit-flex-grow: 1;
          flex-grow: 1;
        }
      }

      p.sign-title {
        justify-content: center;
        -webkit-justify-content: center;
      }

      .operation-container {
        h3 {
          font-size: 1.3rem;
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          text-align: center;
          color: #786f54;
        }
        h4 {
          font-size: 1.3rem;
          text-align: center;
        }
      }

      .firm {
        img {
          width: 130px;
        }
        margin: 0;
        padding: 0;
      }

      .page-header {
        .firm {
          //border-right: 1px solid #ccc;
          padding-right: 20px;

          img {
            height: 23px;
            width: auto;
          }
        }
      }

      .notice-reserved {
        padding-top: 2pt;
        border: 1px solid;
        border-bottom: 2px solid;
        font-weight: bold;
      }

      .glossary {
        span {
          text-decoration: underline;
        }
      }

      .page-header {
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        //border: 1px solid #ccc;
        min-height: 4px;

        display: -webkit-flex;
        -webkit-align-items: center;

        display: flex;
        align-items: center;

        .header-text {
          text-align: right;
          -webkit-flex-grow: 1;
          flex-grow: 1;
        }
      }

      .page-content {
        min-height: 242mm;//272
        padding-top: 0px;//18
        margin: 0;
        padding-left: 0.25cm;
        padding-right: 0.25cm;
      }

      .page-content:first-of-type {
        min-height: 229mm;//272
      }

      .page-footer {
        div.address {
          font-size: 12px;
          line-height: 12px;
          min-height: 16mm;
          padding: 5px;
          width: 49%;
        }

        div.page-number {
          line-height: 12px;
          font-size: 9pt;
        }
      }
    }

    .page:first-of-type {
      break-before: avoid;
    }

    .page.page-1 {
      h3 {
        font-size: 10pt;
        font-weight: bold;
        margin-bottom: 0;
      }

      p.subject {
        font-size: 9pt;
      }

      ul.glossary {
        font-size: 9pt;
      }
    }
  }

  .tampon {
    position: relative;
    font-family: Arial, sans-serif;
    padding-top: 50px;
    line-height: 1.2;
    font-size: 11px;
    background: rgb(50,50,50);
    background: -moz-radial-gradient(circle, rgba(50,50,50,1) 13%, rgba(200,200,200,1) 100%);
    background: -webkit-radial-gradient(circle, rgba(50,50,50,1) 13%, rgba(200,200,200,1) 100%);
    background: radial-gradient(circle, rgba(50,50,50,1) 13%, rgba(200,200,200,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#323232",endColorstr="#c8c8c8",GradientType=1);
    background-clip:text;
    -webkit-background-clip:text;
    color:transparent;

    .signature-svg {
      position: absolute;
      width: 240px;
      height: 134px;
      top: 0;
    }
  }
}
