.contrattype-content{
	.tooltip_spacing{
		margin-left: 1px;
	}
	.client-autocomplete{
		width: 100%;
	}
	select[readonly]
	{
	    pointer-events: none;/*comportement Redonly= disabled*/
		background-color: #e9ecef;
		opacity: 1;
	}
}
.tooltip-large > .tooltip-inner {
	max-width:none;
    width: 60%;
	text-align: left;
	font-size: 14px !important;
	white-space: pre-wrap;
}