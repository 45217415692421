.menu-overlay {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  width: 100%;
  z-index: $zindex-modal;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #148fcc;
  transition: opacity 0.25s;
  color: #fff;
  padding: 0 1rem;


  .menu-content {
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    padding-top: 150px;
    padding-bottom: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    a {
      color: #fff;
    }

    a:hover {
      color: #9fc9eb;
    }

    .menu-groups {
      list-style: none;
      text-align: right;
      flex: 1;
      text-transform: uppercase;
      padding-right: 9rem;

      li {
        padding: 1rem 0;
      }

      li.active {
        color: #9fc9eb;
      }
    }

    .menu-links {
      font-size: 1.1rem;
      list-style: none;
      text-align: left;
      min-width: 300px;
      flex: 2;
    }

    .menu-login {
      flex: 1.3;

      border-left: 1px solid #fff;
      padding-left: 9rem;

      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 1.3rem;
      }

      .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 300px;
      }

      p {
        font-size: 0.8rem;
        a {
          color: #26AECC;
        }
      }

      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        border: 1px solid #fff;
        border-radius: 5px;
        padding: 1rem;
        margin-bottom: 1rem;
        box-shadow: -1px -1px 10px;

        button {
          margin-top: 1rem;
        }

        input {
          text-align: center;
        }
      }


    }
  }

  .menu-close {
    color: #fff;
    position: absolute;
    top: 20px;
    right: 40px;
    font-size: 40px;
  }
}

.menu-overlay.active {
  max-height: 900px;
  opacity: 1;
  transition: opacity 0.25s;
  overflow: inherit;
}