.registration-content .card-header {
	background-color: #fff;
    padding: 0px;
}
.registration-content .card-body {
    padding: 0rem 1.25rem;
}
.registration-content img.logo {
    margin-bottom: 0rem;
    height: 75px;
}
.registration-content span.required {
    color : red;
}
