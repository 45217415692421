:root {
  --ms-tag-bg: #fff;
  --ms-tag-color: var(--bs-secondary);
  --ms-option-bg-pointed: var(--bs-blue);
  --ms-max-height: 20rem;
  --ms-px: 0.75rem;
}
.multiselect-tag{
	border:1px solid var(--bs-secondary);
}
.multiselect.single {
  min-height: 38px!important;

  .multiselect-wrapper {
    min-height: 36px!important;
  }
  .multiselect-dropdown{
    right: auto !important;
  }

}

.multiselect-group-label{
  font-size: 1rem !important;
}
.multiselect-option.is-selected {
  span.text-success {
    color: white !important;
  }
}
