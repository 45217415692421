@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/vanillajs-datepicker/sass/datepicker";

// Import the regular Vue Toastification stylesheets (or create your own)
@import "vue-toastification/src/scss/_variables";
@import "vue-toastification/src/scss/_toastContainer";
@import "vue-toastification/src/scss/_toast";
@import "vue-toastification/src/scss/_closeButton";
@import "vue-toastification/src/scss/_progressBar";
@import "vue-toastification/src/scss/_icon";
@import "vue-toastification/src/scss/animations/_bounce";

@import "base/module";
@import "components/module";
@import "layout/module";
@import "pages/module";

@import "@vueform/multiselect/themes/default";

body {
	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		&.btn {
			&:hover {
				text-decoration: none;
			}
		}

		&.nav-link,
		&.dropdown-item {
			&:hover {
				text-decoration: none;
			}
		}
	}

	.btn-primary,
	.btn-success {
		color: $white;

		&:hover {
			color: $white;
		}
	}

	.btn-link {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
