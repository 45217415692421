.simulation-content {

  .section.active, .section.complete {
    display: block;
  }

  .section {

    display: none;

    padding-bottom: 3rem;
    padding-top: 3rem;

    .step-num {
      width: 2.5rem;
      display: inline-block;
      border-radius: 50%;
      height: 2.5rem;
      background-color: #29a7d6;
      padding-left: 0.85rem;
      padding-top: 0.45rem;
      font-size: 1.5rem;
      color: #fff;
      margin-right: 1rem;
      font-weight: 400;
    }

    h3.section-title {
      font-size: 1.25rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 3rem;
    }
  }

  .profiles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
        justify-content: space-between;
    }

    .profile {

      @include media-breakpoint-down(lg) {
        flex: initial;
        margin-right: 0;
        width: 30%;
      }

      background-color: white;
      cursor: pointer;
      border: 3px solid transparent;
      box-shadow: -2px -2px 10px #ccc;
      text-align: center;
      padding: 2rem;
      margin-right: 1rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      .profile-icon {

        background-position: center center;
        background-repeat: no-repeat;
        min-height: 150px;
        background-size: contain;
        margin-bottom: 5px;

      }

      h4 {
        font-size: 0.8rem;
        text-transform: uppercase;
        margin: 0;
      }

      em {
        color: #ccc;
        font-size: 0.8rem;
        margin-top: auto;
      }
    }

    .profile:first-of-type {
      margin-left: 0;
    }

    .profile.selected {
      border: 3px solid #29a7d6;
    }
  }

  .section-operation {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #F5F5F5;

    .container {
      display: flex;

      h3 {
        flex: 70%;
      }
      h3:first-of-type {
        flex: 30%;
      }

      .list {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        min-height: 10rem;
        border: 1px solid #d9d9d9;

        .list-item {
          flex: 1;
          border-top: 1px solid #d9d9d9;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          align-content: center;
          padding-left: 2rem;
          padding-right: 2rem;
          color: #666666;
        }
        .list-item:first-of-type {
          border-top: 0;
        }
        .selected {
          color: #29a7d6;
        }
      }

      .category-list {

        flex: 30%;

        border-right: 0;

        .list-item {
          cursor: pointer;
          border-right: 1px solid #d9d9d9;
          text-transform: uppercase;
          font-weight: bold;
        }

        .selected {
          border-left: 3px solid #29a7d6;
          border-right: 0;
        }

      }

      .operation-list {

        flex: 70%;

        border-left: 0;

        max-height: 600px;
        overflow-y: scroll;

        .list-item {
          border: 0;

          flex: none;

          div {
            cursor: pointer;
            padding: 2rem;
            border-top: 1px solid #d9d9d9;

            .code {
              text-transform: uppercase;
              font-weight: bold;
            }

            .label {
              margin-left: 2rem;
            }
          }
        }

        .list-item:first-of-type {
          div {
            border-top: 0;
          }
        }

        .selected {
          div {
            background-color: #f5f5f5;
            border-bottom: 1px solid #d9d9d9;
            border-right: 1px solid #d9d9d9;
            border-left: 3px solid #29a7d6;
          }
        }

        .selected:first-of-type {
          div {
            border-top: 1px solid #d9d9d9;
          }
        }

      }
    }
  }

  .group {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .project-operation-nav {
    background-color: #f5f5f5;
    margin-bottom: 1rem;

    .col {
        display: flex;

        .project-operation-nav-item {
            padding: 1rem;
            background-color: #f5f5f5;
            margin-left: 1rem;
            margin-top: 1rem;
            border-top: 3px solid transparent;

            &.active {
                background-color: white;
                border-top: 3px solid #29a7d6;
                a {
                    color: #666666;
                    font-weight: bold;
                }
            }
        }
    }


  }

    
}