.modal.household-precarity-modal {
    .modal-body {
        font-size: 12px;
        h5 {
            font-size: 14px;
            font-weight: bold;
            color: #29a7d6;
        }
    }

    .table {
        
    }
}

.household-precarity-content {
    .precarity-type {
        font-size: 12px;

        h5 {
            font-size: 14px;
        }

        input[type=text] {
            width: 100%;
        }

        td.actions {
            vertical-align: middle;
        }

        tr.add {
            background: none !important;
        }
    }
}