
.layout-public {
  .jumbotron.lp {

    .carousel-content {
      position: relative;
      top: 250px;
      text-align: center;

      h1 {
        font-size: 2.5rem;
        text-align: center;
        position: relative;
        top: 0px;
        color: #fff;
        text-transform: uppercase;
      }

      h3 {
        font-size: 1.10rem;
        text-align: center;
        top: 0px;
        position: relative;
        color: #fff;
        margin-top: 3rem;
      }

      .sep {
        margin-top: 3rem;
        width: 10rem;
        border-bottom: 1px dashed #fff;
        min-height: 1px;
        margin-left: auto;
        margin-right: auto;
      }

      a {
        border: 1px solid #fff;
        border-radius: 15px;
        color: #fff;
        margin-top: 3rem;
      }

    }

    .carousel {
      overflow-y: hidden;

      .carousel-item.active,
      .carousel-item-next,
      .carousel-item-prev {
        display: block;
      }

      .carousel-item {
        width: 100%;
        min-height: 900px;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }
  }
}