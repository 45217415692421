$header-height: 115px;

.layout-user {

  padding: 0;
  margin-right: 15px;
  margin-left: 15px;

  .main-content {
    padding: 2rem;
    min-height: 500px;
  }

  .navbar.main-menu {
    background-color: $m-header-bg;
    padding: 0.5rem 2rem;

    a.navbar-brand {
      padding-bottom: 0;
      padding-top: 0;
      img {
        height: $m-header-logo-height;
      }
    }

    ul {

      align-items: center;

      .nav-item {

        .nav-link {
          //text-transform: uppercase;
          color: $m-header-link-color;

          .badge {
            position: relative;
            top: -0.1rem;
          }
        }

        .nav-link:hover {
          color: $m-header-link-hover-color;
        }
      }

      .profile-dropdown {
          a.nav-link {
              display: flex;
              align-items: center;

              i {
                  margin-right: 5px;
              }
          }
      }
    }
  }

  .jumbotron {
    min-height: 200px;
    background-image: url('../img/layout-user-bg.jpg');

    .header-title {
      position: relative;
      top: 120px;
      padding: 0 2rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      align-content: center;

      h1 {
        font-size: 1.5rem;
        float: left;
        text-align: left;
        color: #fff;
        text-transform: uppercase;
      }

      h3 {
        font-size: 1.2rem;
        color: #fff;
      }

      span {
        padding-top: 5px;
        .btn {
          border: 1px solid #fff;
          color: #fff;
        }
      }

      @media (max-width: 992px) {
        justify-content: space-around;
      }
    }
  }

}