.form-control.form-control-honorific, .form-control-honorific {
    width: 5rem;
}

.input-group.input-daterange {
    width: 20rem;
}

.form-control.form-control-date, .form-control-date {
    width: 15rem;
}

.autocomplete {
    position: relative;

    .autocomplete-input {
        position: relative;
        display: block;

        input {
            padding-right: 2rem;
        }

        i {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    .autocomplete-items {
        position: absolute;
        z-index: 99;
        top: 100%;
        left: 0;
        right: 0;

        .list-group-item:not(:last-child) {
            border-bottom: 0
        }
    }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.input-group > :first-child {
    .dp__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;        
    }
}

.select-loading {
    position: relative;

    .fa-spin {
        position: absolute;
        right: 2.5rem;
        top: .7rem;
    }
}
.col-sm3-to-sm2 {
	width: 16% !important;
}
