.project-overview {

  .project-overview-header {
    height: 2rem;


    h2 {
      font-size: 1.3rem;
    }

    .status {
      font-size: 0.8rem;
    }
  }

  .cee {
    padding-top: 1rem;
    text-align: center;

    strong {
      font-size: 2.8rem;
    }
  }

}

.breakspaces {
  white-space: break-spaces; 
}