table.operation-list {
  a {
    white-space: nowrap;
  }

  .dev-status {

  }

  .dev-status.development {
    background-color: $orange;
  }

  .dev-status.to-fix {
    background-color: $red;
  }

  .dev-status.verified-1 {
    background-color: $teal;
  }

  .dev-status.verified-2 {
    background-color: $green;
  }

  .dev-status.to-test {
    background-color: $indigo;
  }
}

form.operation-form {
  fieldset {
    border-left: 10px solid #ccc;
    border-radius: 10px;
  }

  .code {
    font-family: Consolas, Monaco, "Lucida Console";
    color: #4d00da;
  }
  textarea.code {
    font-size: 0.8rem;
  }

  table {
    width: auto;
    td {
      border: 0;
      text-align: center;
      input {
        text-align: center;
      }
    }
  }

  .details-results {
    max-height: 200px;
    overflow-y: scroll;
  }
  textarea.question_id_fields{
    resize: vertical;
  }
  label.import_ai_csv{
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.sortable-list  {
  list-style:none;
  margin: 0;
  padding: 0 0 0 1.2rem;

  .item {
    position: relative;
    border: 1px solid rgba(0,0,0,0);
    padding-bottom: 1rem;

    &[draggable="true"]::before {
      position: absolute;
      left: -1.2rem;
      top: .5rem;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f58e";
      font-size: .8em;
    }

    &.dragover {
      border-style: dashed;
      border-color: #1f4b73;
    }

    &.dragging {
      opacity: 0.1;
    }

    .card {
      margin-bottom: 0;

      .card-header {
        margin-bottom: 0;
      }
    }
  }
}

.log-container {
  .log-entry {
    font-size: 1rem;

    display: flex;

    .log-info {
      width: 30%;
      color: #ccc;
      font-size: 0.8rem;
    }

    .log-text {
      font-size: 0.8rem;
      flex: 1;
    }

    &.odd {
      background-color: #f5f6f7;
    }
  }
}

.input-font {
  font-size: 1rem !important;
}