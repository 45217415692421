ul.process-steps {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  padding-right: 0;
  padding-left: 0;

  li {
    .step-num {
      background-color: #b3b3b3;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: inline-block;
      text-align: center;
      padding-top: 0.25rem;
      color: #808080;
    }

    .step-title {
      margin-left: 1rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      color: #b3b3b3;
    }

  }

  li.complete, li.active {
    .step-num {
      background-color: #29a7d6;
      color: #fff;
    }

    .step-title {
      color: #29a7d6;
    }
  }
}