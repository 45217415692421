.client-content {
  .client-accounts {
    th {
      border-top: 0;
    }
  }

  .client-projects {
    .client-col {
      display: none;
    }
  }
  .form-check-input.is-invalid ~ .add_document_notification
  {
     color: inherit;
  }
}
.text-align-center {
  text-align: center;
  vertical-align: middle;
}
.red-icon {
  color: red;
}
.blue-icon {
  color: dodgerblue;
}
.green-icon {
  color: mediumseagreen;
}
* {
  box-sizing: border-box;
}

#drop_area {
  width: 100%;
  height: 50px;
  border: 3px dashed #aaaaaa;
  border-radius: 10px;
  text-align: center;
}
.document-link-client {
  cursor: pointer;
  color: white;
}
.document-link-client:hover {
  text-decoration: none;
}
.badge-client {
  background-color: $gray-700;
  font-size: 100%;
  font-weight: 400;
}



