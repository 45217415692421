$blue-san-juan: #333c77;
$red-mandy: #e85c6a;
$gray-g: #eee;

.cotation {
  &.print {
    background-color: #fff;

    .content {
      .page {
        margin: 0;
        padding-right: 0;
        padding-left: 0;
        box-shadow: none;
      }
    }
  }

  font-family: 'Helvetica', 'Arial', sans-serif;
  color: $blue-san-juan;

  .content {
    .page-break {
      page-break-before: always;
    }

    .page-header {
      padding-right: .7cm;
      margin: 0;
      min-height: 27px;

      display: -webkit-flex;
      -webkit-align-items: center;

      display: flex;
      align-items: center;
      font-size: 9pt;

      .brand {
        width: 20%;
      }

      .header-text {
        text-align: right;
        -webkit-flex-grow: 1;
        flex-grow: 1;
      }
    }

    .page {
      page-break-inside: avoid;
      page-break-before: always;

      background-color: #fff;
      box-shadow: 1px 0px 5px grey;
      width: 210mm;
      margin: 0;
      //min-height: 297mm;
      padding: 10mm 5mm;
      margin-bottom: 2rem;
      font-size: 8pt;

      .page-content {
        //min-height: 235mm;//242
        padding-top: 40px;
        margin: 0;
        padding-left: 0.75cm;
        padding-right: 0.75cm;

        .title {
          font-size: 8pt;
          text-align: center;
          border-bottom: 2px solid $gray-g;
          padding-bottom: 1mm;
          margin-bottom: 1rem;
          font-weight: 700;          
        }    
      }

      .page-content:first-of-type {
        min-height: 229mm;//272
      }
    }

    .page:first-of-type {
      page-break-before: avoid;
    }
  }

  .red {
    color: $red-mandy;
  }

  .table {
    font-size: 7pt;
    color: $blue-san-juan;

    .head {
      background-color: $blue-san-juan;
      color: #fff;
      font-weight: 700;
      line-height: 1.2;
    }

    th, td {
      border: 1px solid $gray-g;
      padding: 0.3rem;
    }

    .comments {
      p {
        margin: 0;
        color: $red-mandy;
        font-weight: 700;
      }
    }
  }

  .stitle {
    font-size: 8pt;
    font-weight: 700;
    text-decoration: underline;
  }

  .signature {
    width: 28%;
    margin-left: 71%;
    margin-top: 2rem;
  }

  .conditions {
    font-size: 6pt;

    p {
      margin-bottom: 0;
    }
  }

  @media print {
    .table .head th,
    .table td.head  {
      background-color: $blue-san-juan !important;
      -webkit-print-color-adjust: exact;
    }
  }
}