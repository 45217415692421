.docsya-container {
    .btn-docsya, .btn-non-conformities
    {
        background-color: #00a1f2;
        border-color: #00aaff #00aaff #0088CC;
        color: #FFF;
    }
    .btn-docsya:hover, .btn-docsya:active, .btn-docsya:focus {
        border-color: #000;
    }
    .btn-non-conformities:hover, .btn-non-conformities:active, .btn-non-conformities:focus {
        border-color: #000;
    }
    .btn-non-conformities .badge {
        color: #337ab7;
        background-color: #fff;
    }
    .section-row{
        position: relative;
        width: 100%;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
        min-height: 60px;
        .detail{
            font-size: 14px;
            padding-left: 5px;
        }
        .detail.first{
            margin-top: 14px;
        }
        .detail:last-child {
            margin-bottom: 14px;
        }
    }
    .section{
        padding: 4px 5px 4px 15px;
        background-color: #29A7D6;
        color: #FFF;
        width: 60%;
        font-size: 12px;
        font-weight: bold;
        border-radius: 15px;

        position: absolute;
        left: 0;
        top: 0;
        translate: -10px -50%;
    }
    .pellet{
        margin-right:5px;
    }
    .pellet_yes{
        color: var(--bs-success) ;
    }
    .pellet_no{
        color: var(--bs-danger) ;
    }
    .pellet_nopresent{
        color: var(--bs-gray-500) ;
    }
    .grey{
        color: var(--bs-gray-600) ;
    }
    .table-compare{
        th, td {
            font-size: 14px;
        }
    }
    .explication
    {
        font-weight: bold;
        &.yes{
            color:var(--bs-success) ;
        }
        &.no{
            color:var(--bs-orange) ;
        }
    }
	button.question_link {
        color: inherit ;
        padding: 0px ;
    }
    button.question_link:hover {
        text-decoration: none;
    }

}