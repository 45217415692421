.batch-content {
  .section {
    p {
      margin: 0
    }
    label {
      display: inline-block;
      width: 8rem;
      font-size: 0.8rem;
    }
  }
}

body.batch.print {
  background-color: #fff;

  .content {
    .page-break {
      page-break-before: always;
    }

    .page-header {
      padding-right: .7cm;
      margin: 0;
      min-height: 27px;

      display: -webkit-flex;
      -webkit-align-items: center;

      display: flex;
      align-items: center;
      font-size: 9pt;

      .brand {
        width: 20%;
      }

      .header-text {
        text-align: right;
        -webkit-flex-grow: 1;
        flex-grow: 1;
      }
    }

    .page {
      page-break-inside: avoid;
      page-break-before: always;

      background-color: #fff;
      box-shadow: 1px 0px 5px grey;
      width: 210mm;
      margin: 0;
      min-height: 297mm;
      padding: 10mm 5mm;
      font-size: 12pt;

      .page-content {
        min-height: 235mm;//242
        padding-top: 40px;
        margin: 0;
        padding-left: 0.75cm;
        padding-right: 0.75cm;
   
      }

      .page-content:first-of-type {
        min-height: 229mm;//272
      }
    }

    .page:first-of-type {
      page-break-before: avoid;
    }

    .page-footer {
      min-height: 16mm;
      margin: 0;
      text-align: center;
    }
  }
}