.document-container {
    .document-row {
        position: relative;
        width: 100%;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
        min-height: 60px;

        .btn-action {
            font-size: 12px;
            width: 27px;
            height: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            border-width: 0px;
        }
        .document-rubric {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            left: -8px;
            top: -14px;

            .title-document {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 12px;
                font-weight: bold;
                padding: 3px 7px;
                border-radius: 15px;
                background-color:#29A7D6 ;

                span.bulle {
                    padding: 5px;
                    color: #fff;
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    margin-right: 5px;

                    &.black{
                        background-color:#D3D3D3;
                    }
                    &.blue{
                        background-color:rgba(67, 178, 219, 1);
                    }
                }

                .info-bulle {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    margin-left:4px;
                    i {
                       padding: 5px;
                       color: rgba(67, 178, 219, 1);
                       width: 15px;
                       height: 15px;
                       border-radius: 100%;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       background-color:#fff;
                       margin-left: 4px;
                   }
                   .info-bulle-text {
                    visibility: hidden;
                    width: 460px;
                    height: auto;
                    background-color: rgba(51, 51, 51, 1);
                    color: #fff;
                    border-radius: 6px;
                    padding: 15px;
                    position: absolute;
                    z-index: 1;
                        bottom: 125%; /* Adjust this value to control distance from the icon */
                        left: 50%;
                        transform: translateX(-50%);
                        opacity: 0;
                        transition: opacity 0.3s, visibility 0.3s;
                        text-align: left;
                        .info {
                            color: rgba(67, 178, 219, 1);
                            display: inline
                        }
                    }
                }
                .info-bulle:hover .info-bulle-text {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .add-document {
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                    text-decoration: none;
                }
                label{
                    cursor: pointer;
                }

                .add {
                    background-color: #29A7D6;
                    color: #fff;
                    border-radius: 50%;
                }

                .upload{
                    cursor: pointer ;
                    margin-top: 7px;
                }
            }
        }

        .title-document.black {
         background-color:#868e96 ;
     }
     .title-document.blue {
         background-color:#29A7D6 ;
     }
     .documents{
         .item-document {
            display: flex;
            justify-content : flex-start ;
            margin: 12px 2px 2px 0px ;
            gap: 4px;

            .col-actions{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right:4px;
                gap:4px;
                z-index: 1000;
            }
            .col-actions.has-border-right{
                border-right: 4px solid #ddd;
            }
            .col-actions.has-update-status{
                width:115px;
            }
            .col-no-docsya{
                display: flex;
                width:272px;
            }
            .col-docsya{
                display: flex;
                padding-left: 6px;
                font-size: 12px;
                gap: 4px;

                span.status {
                    font-size: 13px;
                    width: 80px;
                    display: flex;
                    align-items: center
                }
                span.document_date{
                    display: inline-block;
                    width:80px;
                    color: $gray-400;
                }
                .btn-danger {
                    background-color: #d2322d;
                    border-color: #d2322d #d2322d #a82824;
                    color: #FFF;
                    padding: 0.25rem 0.45rem;
                }
                .btn-danger:hover, .btn-danger.hover {
                    background-color: #d9514d;
                    border-color: #db5b57 #db5b57 #d2322d;
                    color: #FFF;
                }
                .btn-danger:focus, .btn-danger.focus {
                    box-shadow: 0 0 0 3px rgba(210, 50, 45, 0.5);
                    color: #FFF;
                }
                .btn-danger.disabled, .btn-danger:disabled {
                    background-color: #d2322d;
                    border-color: #d2322d #d2322d #a82824;
                }
                .btn-danger:active, .btn-danger.active {
                    background-color: #b32b26;
                    background-image: none;
                    border-color: #a82824 #a82824 #7e1e1b;
                }
            }
            .info {
                background-color: #29A7D6;
                font-size: 12px;
                border-radius: 50%;
            }
            .trash {
                background-color: #dc3545;
                color: #fff;
            }
            .document-list {
                display: flex ;
                align-items: center;
                padding-right: 4px;
                justify-content: space-between;
                flex: 1 1;

                .document-link {
                    font-size: 14px;
                }
                .document-link:hover {
                    text-decoration: none;
                }

                .document-edit-form {
                    button {
                        cursor: pointer;
                    }
                }

                .document-date, .document-note {
                    font-size: 12px;
                    color: $gray-400;
                }
                .document-invalid{
                    font-size: 11px;
                }
            }
            .document-list.has-border-right{
                border-right: 4px solid #ddd;
            }
        }
        .item-document:last-child {
            margin-bottom: 10px;
        }
    }
}
.docsya-progress-container {
    display: flex;
    width: 100px;
    align-items: center;

    .progress-back{
        width: 100%;
        z-index: 0 !important;
    }
    .progress-text{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}
.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none !important;
    padding: 0.25rem 0.45rem;
}
.btn-default:hover, .btn-default:active, .btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
}
