.project-list-content {

  .docs-dropdown {
    display: inline-block;
    cursor: pointer;
  }

  .progress {
    background-color: #ccc;
    height: auto;
  }

  td.bonus-col, td.cee-col {
      white-space: nowrap;
  }

  tr.orange {
    background-color: #ffc966;
  }

  tr.flash-red {
    background: #f5c6cb;
    background-color:white;
    animation: colorblink 1s infinite;
  }
  @keyframes colorblink {
    0%  {
      background-color:#f5c6cb;
    }
    100% {
      background-color:white;
    }
  }
}