.header {
  .jumbotron {
    position: relative;
    padding: 0;
    margin: 0;

    border-radius: 0;

    width: 100%;
    min-height: 400px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}