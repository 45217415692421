.dashboard-content {
  .dashboard-filters {
    margin-bottom: 2rem;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      .client-autocomplete {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 1rem;
      }
    }

    .btn-link {
      flex: 1;
      text-align: left;
    }
  }
  .stat-filters {
    display: grid;
    --bs-rows:2;
  }
  .card-group.merge {
    //background-color: white;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border: 1px solid rgba(0,0,0,0.125);
    padding-top: 5px;

    .card {
      border-left: 0px;
      border-top: 0px;
      border-bottom: 0px;
      border-radius: 0 !important;

      &:last-of-type {
        border-right: 0px;
      }
    }
  }

  .card {
    .card-header, .card-footer {
      background-color: #fff;
    }
  }

  .client-autocomplete {
    width: 20rem;
  }

  span.twitter-typeahead {
    .tt-menu {
      width: 20rem;
    }
  }

  .data-list {
    font-size: 12px;
    color: #999;

    max-height: 300px;
    overflow-y: scroll;

    .data-list-item {
      margin-bottom: 1rem;
      text-align: left;
    }

    .table {
      th {
        border-top: 0;
      }
    }

    .label {
      overflow-x: hidden;
      white-space: nowrap;
    }

    .value {
      font-weight: 600;
    }

    .progress {
      height: 5px;
      .progress-bar {
        color: #333;
        text-align: left;

        span {
          white-space: nowrap;
          padding-left: 5px;
        }
      }
    }
  }

  .operation-coverage-labels {
    font-size: 0.8rem;
    max-height: 150px;
    overflow-y: scroll;
    min-width: 150px;
  }

  .stats-table {
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;

    .stats-row {
      &.clickable {
        cursor: pointer;
      }
      
      display: flex;
      border-bottom: 1px solid rgba(0,0,0,0.125);
      border-top: 0;
      justify-content: space-between;
      padding: 2px;

      &:hover {
        background-color: rgba(0,0,0,0.125);
      }

      &.row-header {
        color: #1cb9a4 !important;
        background: #00000012;
      }

      div {
        width: 20%;
        text-align: center;

        &:last-of-type {
          width: 10%;
        }
      }

      .legend {
        text-align: left;
        width: 30%;
        overflow-x: hidden;
      }
    }

    &.coverage, &.houses {
      .stats-row {
        div {
          &:last-of-type {
            width: auto;
          }
        }

        .legend {
          width: 65%;
        }
      }
    }

    .sep {
      height: 1rem;
    }

  }

  tbody.collapse.in {
    display: table-row-group;
  }
  .expand-button {
    position: relative;
  }

  .pointer .expand-button:before
  {
    content: "\f147";
    font-family: FontAwesome;
  }
  .pointer.collapsed .expand-button:before
  {
    content: "\f196";
    font-family: FontAwesome;
  }

  #mbtable {
    border-color: black;
    background: white;
  }

  #smbtable {
    width:80%; margin-left: 20%
  }
  .content-mb {
    margin: auto;
    width: 70%;
  }
  .limited_width_1 {
    width: 35%;
  }
  .limited_width_2 {
    width: 20%;
  }

  tr.orange {
    background-color: #ffc966;
  }

  tr.flash-red {
    background: #f5c6cb;
    background-color:white;
    animation: colorblink 1s infinite;
  }
  @keyframes colorblink {
    0%  {
      background-color:#f5c6cb;
    }
    100% {
      background-color:white;
    }
  }
  #td-name {
    max-width: 500px;
    width: 500px;
  }
  #div-name {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    overflow-x: scroll;
  }
  .all-in-one-line{
    white-space:nowrap;
  }

  .select_fix_width {
    width: max-content;
  }
  .bg-lightgrey {
    background-color: lightgrey
  }
  .sender_date_bloc {
    font-size: smaller;
  }
  .center-td {
    text-align: center;
  }

  .chart-container {
    position: relative;
  }
}